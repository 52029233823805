module.exports = {
  "signup": {
    "url": `${process.env.REACT_APP_API_PREFIX}/signup`,
    "callback": "api/signup/pt"
  },
  "resend-confirmation": {
    "url": `${process.env.REACT_APP_API_PREFIX}/resend-confirmation`,
    "callback": "api/resend-confirmation"
  },
  "confirm-signup-link-callback": {
    "url": `${process.env.REACT_APP_API_PREFIX}/confirm-signup-link-callback`,
    "callback": "api/confirm-signup-link-callback/pt"
  },
};
